export default {
    // common
    yes: 'Да',
    no: 'Нет',
    name: 'Имя',
    email: 'Е-мейл',
    status: 'Статус',
    expiration: 'Просрочка',
    balance: 'Баланс',
    registrationDate: 'Дата регистрации',
    registrationDateShort: 'Дата рег.',
    activated: 'Активирован',
    activatedShort: 'Акт',
    confirmed: 'Подтвержден',
    confirmedShort: 'П-eн',
    search: 'Поиск',
    send: 'Отправить',
    create: 'Создать',
    load: 'Скачать',
    created: 'Создан',
    referralWord: 'Реферальный код',
    additionalOptions: 'Дополнительные опции',
    userChannelName: 'Имя канала',
    id: 'ID',
    compainCode: 'Compain code',
    twoFactorAuthentification: 'Двухфакторная аутентификация',
    twoFactorAuthentificationShort: '2FA',
    cancel: 'Отмена',
    date: 'Дата',
    network: 'Сеть',
    add: 'Добавить',
    optional: 'Опционально',
    change: 'Изменить',
    value: 'Значение',
    link: 'Ссылка',
    warning: 'Внимание',
    backToPage: 'Вернутся на страницу',
    loginWord: 'Вход',
    strategyWord: 'Стратегия',
    detail: 'Подробно',
    all: 'Все',
    or: 'или',
    role: 'Статус',
    apply: 'Применить',
    more: 'Подробнее',
    country: 'Страна',
    lastSeenDay: 'Последняя активность',
    exchanges: 'Биржи',
    copy: 'Копировать',
    copyed: 'Скопировано',
    remove: 'Удалить',
    save: 'Сохранить',
    tag: 'Тег',
    from: 'От',
    to: 'До',
    userId: 'ID пользователя',

    // validation
    enterText: 'Введите текст',
    enterValue: 'Введите значение',
    enterId: 'Введите ID',
    enterName: 'Введите имя',
    enterLink: 'Введите ссылку',
    enterEmail: 'Введите Е-мейл',
    enterCorrectEmail: 'Введите корректный Е-мейл',
    enterPassword: 'Введите пароль',
    enterCode: 'Введите 2FA код',
    enterRecaptcha: 'Пройдите каптчу',
    enterCountry: 'Введите страну',
    enterCorrectCountry: 'Выберите страну из саписка',
    textLength: 'Длина должна быть от {from} до {to}',
    tagValidationErrorText: 'Только англ. буквы, цифры и символы "_" ","',

    // pluralization

    second: '0с | {n}с | {n}с | {n}с',
    minute: '0м | {n}м | {n}м | {n}м',
    hour: '0ч | {n}ч | {n}ч | {n}ч',
    day: '0д | {n}д | {n}д | {n}д',
    week: '0 нед | {n} нед | {n} нед | {n} нед',
    month: '0 мес | {n} мес | {n} мес | {n} мес',
    secondFullText: '0 секунд | {n} секунда | {n} секунды | {n} секунд',
    minuteFullText: '0 минут | {n} минута | {n} минуты | {n} минут',
    hourFullText: '0 часов | {n} час | {n} часа | {n} часов',
    dayFullText: '0 дней | {n} день | {n} дня | {n} дней',
    weekFullText: '0 недель | {n} неделя | {n} недели | {n} недель',
    monthFullText: '0 месяцев | {n} месяц | {n} месяца | {n} месяцев',

    elementDatePicker: {
        startDate: 'От',
        separator: '-',
        endDate: 'До'
    },

    // pages/components
    login: {
        password: 'Пароль',
        code: '2FA код'
    },
    users: {
        pageTitle: 'Поиск пользователей или стратегий',
        openUserPageBtnTitle: 'Открыть страницу пользователя',
        editUserPageBtnTitle: 'Редактировать данные пользователя',
        searchByEmailOrId: 'Поиск пользователя по Е-мейл или ID',
        searchByRole: 'Поиск пользователя по статусу',
        searchByDate: 'Поиск пользователя по дате создания',
        lastWeek: 'Неделя',
        lastMounth: 'Месяц',
        lastTreeMounth: '3 месяца',
        userId: 'ID пользователя',
        searchByStrategyId: 'Поиск стратегии по ID',
        strategyId: 'ID стратегии',
        error: {
            indicateStatusOrCreationDate: 'Укажите статус или дату создания'
        }
    },
    user: {
        pageTitle: 'Пользователь',
        mainData: 'Основные данные',
        changeMainData: 'Изменить основные данные',
        changeLicence: 'Изменить лицензию',
        messageSent: 'Сообщение отправлено',
        portfolioCacheCleared: 'Кеш портфолио очищен',
        btn: {
            userReferrals: 'Рефералы пользователя',
            accruals: 'Начисления',
            portfolio: 'Портфолио',
            strategies: 'Стратегии',
            sendTelegramMsg: 'Сообщение в Телеграм',
            resetCache: 'Очистить кеш портфолио'
        },
        modal: {
            sendTelegramMsg: {
                title: 'Сообщение в Телеграм',
                text: 'Текст',
                textPlaceholder: 'Введите текст сообщения'
            },
            changeLicence: {
                newLicence: 'Новая лицензия'
            },
            changeLicenceLink: {
                title: 'Ссылка для оплаты'
            }
        }
    },
    userSettings: {
        parameters: 'Параметры',
        name: 'Название',
        change: 'Изменить',
        changeValue: 'Изменить значение',
        changeValueTo: 'Изменить значение на',
        parameterChanged: 'Параметер изменен',
        on: 'Вкл.',
        off: 'Выкл.'
    },
    telegramBots: {
        pageTitle: 'Телеграм боты',
        createTelegramBot: 'Создать Телеграм бот',
        enabled: 'Доступен',
        telegramBotAdded: 'Новый телеграм бот добавлен',
        enterToken: 'Введите API token',
        modal: {
            title: 'Новый Телеграм бот',
            link: 'Ссылка на бот',
            token: 'API token'
        }
    },
    referrals: {
        referrals: 'Рефералы',
        userReferrals: 'Рефералы пользователя',
        code: 'Код',
        addNewReferral: 'Добавить новый реферал',
        name: 'Название',
        created: 'Создан',
        comment: 'Примечание',
        expire: 'Истекает',
        credit: 'Кредит',
        limit: 'Лимит',
        registrations: 'Регистраций',
        programReferrals: 'Рефералы программы',
        programId: 'ID программы',
        copyLink: 'Копировать реферальную ссылку',
        pickDate: 'Выберите дату',
        enterName: 'Введите название',
        userId: 'ID пользователя',
        rid: 'Rid',
        tag: 'Тег',
        role: 'Роль',
        disable: 'Отключить',
        disableReferral: 'Отключить реферал',
        status: 'Статус',
        successMsg: {
            referralLinkCopied: 'Реферальная ссылка скопирована',
            referralDisabled: 'Реферал отключен',
            referralAdded: 'Новый реферал добавлен'
        }
    },
    referralProgram: {
        addNewReferralProgram: 'Добавить новую программу',
        campaign: 'Кампания',
        claim: 'Заявка',
        comment: 'Комментарий',
        description: 'Описание',
        status: 'Состояние',
        fixed: 'Фиксированный',
        pageTitle: 'Реферальные программы',
        percent: 'Процент',
        referral: 'Реферал',
        referrer: 'Реферер',
        summary: 'Краткое описание',
        updated: 'Программа изменена',
        added: 'Программа создана'
    },
    createReferralProgram: {
        addNewClaim: 'Добавить новую заявку',
        campaign: 'Кампания',
        claim: 'Заявка',
        comment: 'Комментарий',
        description: 'Описание',
        descriptionEN: 'Описание (EN)',
        descriptionRU: 'Описание (RU)',
        status: 'Состояние',
        days: 'Дней',
        referral: 'Реферал',
        referrer: 'Реферер',
        summary: 'Краткое описание',
        summaryEN: 'Краткое описание (EN)',
        summaryRU: 'Краткое описание (RU)',
        title: 'Добавить новую реферальную программу',
        type: 'Тип',
        updateTitle: 'Изменить реферальную программу',
        value: 'Количество'
    },
    portfolio: {
        pageTitle: 'Портфель пользователя',
        hideLow: 'Скрыть малые активы',
        asset: 'Монета',
        total: 'Общий Баланс',
        free: 'Доступный баланс',
        reserve: 'Резерв (биржа/стратегии)',
        value: 'Стоимость',
        headerTotal: 'Всего',
        logs: {
            title: 'Скачать логи портфолио',
            dayAgo: 'Последний день',
            threeDaysAgo: 'Последние 3 дня',
            fiveDaysAgo: 'Последние 5 дней'
        }
    },
    accruals: {
        pageTitle: 'Реферальные начисления',
        points: 'Баллы',
        reason: 'Описание',
        comment: 'Комментарий',
        change: 'Изменить',
        successMsg: {
            onAdd: 'Успешное изменение'
        }
    },
    changeUserDataModal: {
        title: 'Изменение данных',
        comment: 'Примечание',
        saveChanges: 'Сохранить изменения',
        deactivate: 'Деактивировать',
        text1: 'Вы уверены что хотите',
        text2: 'деактивировать пользователя?',
        text3: 'Деактивация будет доступна через',
        sec: 'сек.',
        userDataChanged: 'Данные пользователя изменены'
    },
    header: {
        menu: 'Меню',
        confirmText: 'Покинуть админку и разлогиниться?',
        page: {
            users: 'Пользователи',
            referrals: 'Рефералы',
            telegramBots: 'Телеграм боты',
            billing: 'Биллинг',
            referralPrograms: 'Реферальные программы',
            receipts: 'Оплаты'
        },
        logout: 'Выход',
        hello: 'Здравствуйте,'
    },
    strategies: {
        pageTitle: 'Стратегии пользоватея',
        userId: 'User ID',
        table: {
            type: 'Тип',
            marketAndPair: 'Рынок/Пара',
            reserve: 'Резерв',
            strategyId: 'Strategy ID',
            runId: 'Run ID'
        },
        openStrategyPage: 'Открыть страницу стратегии',
        strategyOrders: 'Ордера стратегии'
    },
    strategy: {
        backToStrategiesList: 'Назад к списку стратегий',
        backToUserPage: 'К странице пользователя',
        active: 'Активен',
        inactive: 'Неактивен',
        pending: 'В ожидании',
        stopStrategy: 'Остановить стратегию',
        deleteStrategy: 'Удалить стратегию',
        config: 'Конфиг',
        info: 'Info',
        data: 'Данные',
        logs: {
            title: 'Скачать логи стратегии',
            dayAgo: 'Последний день',
            threeDaysAgo: 'Последние 3 дня',
            fiveDaysAgo: 'Последние 5 дней'
        },
        tableData: {
            type: 'Тип',
            name: 'Имя',
            exchange: 'Биржа',
            symbol: 'Символ',
            status: 'Статус',
            started: 'Запущена',
            direction: 'Сторона (long/short)',
            timeframe: 'Таймфрейм',
            balanceTotal: 'Баланс total',
            balanceAllocated: 'Баланс allocated',
            profit: 'Профит',
            userId: 'User ID',
            strategyId: 'Strategy ID',
            strategyRunId: 'Strategy run ID',
            notice: 'Примечание',
            orders: 'Ордера',
            stoploss: 'Стоплосс',
            state: 'State',
            allocation: 'Allocation'
        }
    },
    groupTelegramMessage: {
        btn: 'Групповое сообщение в Телеграм',
        modal: {
            title: 'Групповое сообщение в Telegram',
            text: 'Текст',
            textPlaceholder: 'Введите текст сообщения',
            role: 'Статус',
            activeStatus: 'Состояние',
            active: 'Активные',
            inactive: 'Не активные',
            messageSent: 'Групповое Телеграм сообщение отправлено'
        }
    },
    groupEmailMessage: {
        btn: 'Групповое сообщение по Е-мейл',
        modal: {
            title: 'Групповое Е-мейл сообщение',
            subject: 'Тема',
            text: 'Текст',
            textPlaceholder: 'Введите текст сообщения',
            role: 'Роль',
            activeStatus: 'Состояние',
            active: 'Активные',
            inactive: 'Не активные',
            messageSent: 'Групповое Е-мейл сообщение отправлено',
            sendingParameters: 'Параметры отправки',
            options: 'Опции',
            list: 'Список ID',
            listPlaceholder: 'Введите ID пользователей через запятую'
        }
    },
    billing: {
        pageTitle: 'Биллинг',
        promoCode: {
            createPromoCode: 'Создать промокод для лицензии',
            readPromoCode: 'Получить информацию о промокоде',
            deletePromoCode: 'Удалить промокод',
            code: 'Промокод',
            codeType: 'Тип промокода',
            singleUse: 'Одноразовый',
            startDate: 'Действует с',
            codeTypeLicense: 'Промо лицензия',
            codeTypePurchase: 'Скидка на покупку',
            expires: 'Действует до',
            pickDate: 'Выберите дату',
            licence: 'Лицензия',
            percent: 'Процент скидки',
            userId: 'ID пользователя',
            allData: 'Все данные',
            validation: {
                enterCode: 'Введите промокод',
                enterDate: 'Введите дату',
                pickType: 'Выберите тип промокода',
                pickLicence: 'Выберите лицензию',
                enterPercent: 'Введите процент скидки',
                percentRule: 'Процент должен быть больше 0 и меньше 100'
            }
        },
        error: {
            getLicencesList: 'Ошибка при получении данных о лицензиях',
            createPromoCode: 'Ошибка при создании промокода',
            readPromoCode: 'Ошибка при чтении промокода',
            deletePromoCode: 'Ошибка при удалении промокода'
        },
        success: {
            createPromoCode: 'Создан новый промокод',
            deletePromoCode: 'Промо код удален'
        }
    },
    receipts: {
        pageTitle: 'Оплаты',
        dateRange: 'Временной интервал (UTC+0)',
        dateAndTime: 'Дата время (UTC+0)',
        dateAndTimeLocal: 'Дата время (локальное)',
        defaultRangeText: 'По умолчанию - за сегодняшний день (с 00:00 UTC)',
        licence: 'Лицензия',
        price: 'Цена',
        viewUserData: 'Посмотреть данные пользователя',
        loadUserData: 'Загрузите данные пользователя (кнопка "ID пользователя")',
        goToUserPage: 'На страницу пользователя',
        downloadReceipt: 'Скачать чек',
        merchantTradeId: 'Merchant Trade ID',
        duration: 'Продолжительность',
        licenseId: 'ID лицензии',
        invoiceId: 'ID инвойса'
    },
    countryCodes: {
        AF: 'Афганистан',
        US: 'Соединенные Штаты Америки',
        AL: 'Албания',
        DZ: 'Алжир',
        AD: 'Андорра',
        AO: 'Ангола',
        AI: 'Ангилья',
        AQ: 'Антарктида',
        AG: 'Антигуа и Барбуда',
        AR: 'Аргентина',
        AM: 'Армения',
        AW: 'Аруба',
        AU: 'Австралия',
        AT: 'Австрия',
        AZ: 'Азербайджан',
        BS: 'Багамы',
        BH: 'Бахрейн',
        BD: 'Бангладеш',
        BB: 'Барбадос',
        BY: 'Беларусь',
        BE: 'Бельгия',
        BZ: 'Белиз',
        BJ: 'Бенин',
        BM: 'Бермуды',
        BT: 'Бутан',
        BO: 'Боливия, Многонациональное Государство',
        BA: 'Босния и Герцеговина',
        BW: 'Ботсвана',
        BR: 'Бразилия',
        IO: 'Британская территория в Индийском океане',
        BN: 'Бруней-Даруссалам',
        BG: 'Болгария',
        BF: 'Буркина-Фасо',
        BI: 'Бурунди',
        KH: 'Камбоджа',
        CM: 'Камерун',
        CA: 'Канада',
        CV: 'Кабо-Верде',
        KY: 'Каймановы острова',
        CF: 'Центральноафриканская Республика',
        TD: 'Чад',
        CL: 'Чили',
        CN: 'Китай',
        CX: 'Остров Рождества',
        CC: 'Кокосовые (Килинг) острова',
        CO: 'Колумбия',
        KM: 'Коморские острова',
        CG: 'Конго',
        CD: 'Конго, Демократическая Республика Конго',
        CK: 'Острова Кука',
        CR: 'Коста-Рика',
        CI: 'Кот-д\'Ивуар',
        HR: 'Хорватия',
        CU: 'Куба',
        CY: 'Кипр',
        CZ: 'Чехия',
        DK: 'Дания',
        DJ: 'Джибути',
        DM: 'Доминика',
        DO: 'Доминиканская Республика',
        EC: 'Эквадор',
        EG: 'Египет',
        SV: 'Сальвадор',
        GQ: 'Экваториальная Гвинея',
        ER: 'Эритрея',
        EE: 'Эстония',
        ET: 'Эфиопия',
        FK: 'Фолклендские (Мальвинские) острова',
        FO: 'Фарерские острова',
        FJ: 'Фиджи',
        FI: 'Финляндия',
        FR: 'Франция',
        GF: 'Французская Гвиана',
        PF: 'Французская Полинезия',
        GA: 'Габон',
        GM: 'Гамбия',
        GE: 'Грузия',
        DE: 'Германия',
        GH: 'Гана',
        GI: 'Гибралтар',
        GR: 'Греция',
        GL: 'Гренландия',
        GD: 'Гренада',
        GP: 'Гваделупа',
        GU: 'Гуам',
        GT: 'Гватемала',
        GG: 'Гернси',
        GN: 'Гвинея',
        GW: 'Гвинея-Бисау',
        GY: 'Гайана',
        HT: 'Гаити',
        VA: 'Святой Престол (город-государство Ватикан)',
        HN: 'Гондурас',
        HK: 'Гонконг',
        HU: 'Венгрия',
        IS: 'Исландия',
        IN: 'Индия',
        ID: 'Индонезия',
        IR: 'Иран, Исламская Республика Персидского залива',
        IQ: 'Ирак',
        IE: 'Ирландия',
        IM: 'Остров Мэн',
        IL: 'Израиль',
        IT: 'Италия',
        JM: 'Ямайка',
        JP: 'Япония',
        JE: 'Джерси',
        JO: 'Иордания',
        KZ: 'Казахстан',
        KE: 'Кения',
        KI: 'Кирибати',
        KP: 'Корея, Корейская Народно-Демократическая Республика',
        KR: 'Корея, Республика Южная Корея',
        KW: 'Кувейт',
        KG: 'Кыргызстан',
        LA: 'Лаос',
        LV: 'Латвия',
        LB: 'Ливан',
        LS: 'Лесото',
        LR: 'Либерия',
        LY: 'Ливийская Арабская Джамахирия',
        LI: 'Лихтенштейн',
        LT: 'Литва',
        LU: 'Люксембург',
        MO: 'Макао',
        MK: 'Македония',
        MG: 'Мадагаскар',
        MW: 'Малави',
        MY: 'Малайзия',
        MV: 'Мальдивы',
        ML: 'Мали',
        MT: 'Мальта',
        MH: 'Маршалловы Острова',
        MQ: 'Мартиника',
        MR: 'Мавритания',
        MU: 'Маврикий',
        YT: 'Майотта',
        MX: 'Мексика',
        FM: 'Микронезия, Федеративные Штаты Микронезии',
        MD: 'Молдова',
        MC: 'Монако',
        MN: 'Монголия',
        ME: 'Черногория',
        MS: 'Монтсеррат',
        MA: 'Марокко',
        MZ: 'Мозамбик',
        MM: 'Мьянма',
        NA: 'Намибия',
        NR: 'Науру',
        NP: 'Непал',
        NL: 'Нидерланды',
        AN: 'Нидерландские Антильские острова',
        NC: 'Новая Каледония',
        NZ: 'Новая Зеландия',
        NI: 'Никарагуа',
        NE: 'Нигер',
        NG: 'Нигерия',
        NU: 'Ниуэ',
        NF: 'Остров Норфолк',
        MP: 'Северные Марианские острова',
        NO: 'Норвегия',
        OM: 'Оман',
        PK: 'Пакистан',
        PW: 'Палау',
        PS: 'Палестинская территория, оккупированная',
        PA: 'Панама',
        PG: 'Папуа-Новая Гвинея',
        PY: 'Парагвай',
        PE: 'Перу',
        PH: 'Филиппины',
        PN: 'Питкэрн',
        PL: 'Польша',
        PT: 'Португалия',
        PR: 'Пуэрто-Рико',
        QA: 'Катар',
        RO: 'Румыния',
        RU: 'Российская Федерация',
        RW: 'Руанда',
        RE: 'Реюньон',
        BL: 'Сен-Бартелеми',
        SH: 'Острова Святой Елены, Вознесения и Тристан-да-Кунья',
        KN: 'Сент-Китс и Невис',
        LC: 'Сент-Люсия',
        MF: 'Святой Мартин',
        PM: 'Сен-Пьер и Микелон',
        VC: 'Сент-Винсент и Гренадины',
        WS: 'Самоа',
        SM: 'Сан-Марино',
        ST: 'Сан-Томе и Принсипи',
        SA: 'Саудовская Аравия',
        SN: 'Сенегал',
        RS: 'Сербия',
        SG: 'Сингапур',
        SK: 'Словакия',
        SI: 'Словения',
        SB: 'Соломоновы острова',
        SO: 'Сомали',
        ZA: 'Южная Африка',
        SS: 'Южный Судан',
        GS: 'Южная Георгия и Южные Сандвичевы острова',
        ES: 'Испания',
        LK: 'Шри-Ланка',
        SD: 'Судан',
        SR: 'Суринам',
        SJ: 'Шпицберген и Ян-Майен',
        SZ: 'Свазиленд',
        SE: 'Швеция',
        CH: 'Швейцария',
        SY: 'Сирийская Арабская Республика',
        TW: 'Тайвань',
        TJ: 'Таджикистан',
        TZ: 'Танзания, Объединенная Республика Танзания',
        TH: 'Таиланд',
        TL: 'Тимор-Лешти',
        TG: 'Того',
        TK: 'Токелау',
        TO: 'Тонга',
        TT: 'Тринидад и Тобаго',
        TN: 'Тунис',
        TR: 'Турция',
        TM: 'Туркменистан',
        TC: 'Острова Теркс и Кайкос',
        TV: 'Тувалу',
        UG: 'Уганда',
        UA: 'Украина',
        AE: 'Объединенные Арабские Эмираты',
        GB: 'Великобритания',
        UY: 'Уругвай',
        UZ: 'Узбекистан',
        VU: 'Вануату',
        VE: 'Венесуэла, Боливарианская Республика Венесуэла',
        VN: 'Вьетнам',
        VG: 'Виргинские острова, Британские',
        WF: 'Уоллис и Футуна',
        YE: 'Йемен',
        ZM: 'Замбия',
        ZW: 'Зимбабве',
        SC: 'Сейшелы',
        SL: 'Сьерра-Леоне'
    }
};
