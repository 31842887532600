import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '../components/pages/login/Login.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { auth: false }
    },
    {
        path: '/',
        name: 'main',
        redirect: '/users',
        component: () => import('../components/pages/Main.vue'),
        meta: { auth: true },
        children: [
            {
                path: '/users',
                name: 'users',
                component: () => import('../components/pages/users/Users.vue')
            },
            {
                path: '/user',
                name: 'user',
                component: () => import('../components/pages/user/User.vue')
            },
            {
                path: '/referrals',
                name: 'referrals',
                component: () => import('../components/pages/referrals/Referrals.vue')
            },
            {
                path: '/referral-programs',
                name: 'referral-program',
                component: () => import('../components/pages/referralProgram/ReferralProgram.vue')
            },
            {
                path: '/strategies',
                name: 'strategies',
                component: () => import('../components/pages/strategies/Strategies.vue')
            },
            {
                path: '/strategy',
                name: 'strategy',
                component: () => import('../components/pages/strategy/Strategy.vue')
            },
            {
                path: '/portfolio',
                name: 'portfolio',
                component: () => import('../components/pages/portfolio/Portfolio.vue')
            },
            {
                path: '/user-referrals',
                name: 'user-referrals',
                component: () => import('../components/pages/userReferrals/UserReferrals.vue')
            },
            {
                path: '/accruals',
                name: 'accruals',
                component: () => import('../components/pages/accruals/Accruals.vue')
            },
            {
                path: '/telegram-bots',
                name: 'telegram-bots',
                component: () => import('../components/pages/telegram-bots/TelegramBots.vue')
            },
            {
                path: '/billing-interface',
                name: 'billing-interface',
                component: () => import('../components/pages/billing-interface/BillingInterface.vue')
            },
            {
                path: '/receipts',
                name: 'receipts',
                component: () => import('../components/pages/receipts/Receipts.vue')
            }
        ]
    },
    {
        path: '/403',
        name: 'code-403',
        component: () => import('../components/pages/error/Code403.vue')
    },
    {
        path: '/404',
        name: 'code-404',
        component: () => import('../components/pages/error/Code404.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
