/* eslint-disable no-console */
import type { FormInstance } from 'element-plus';

export default {
    methods: {
        $successNotify(message: string, title = 'Success'): void {
            // FIX ANY
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this as any).$notify({
                title,
                dangerouslyUseHTMLString: true,
                message,
                duration: 3000,
                type: 'success'
            });
        },

        // FIX ANY
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $errorMsg(additionalMsg: string, err: any): void {
            if (['invalid', 'cancel'].includes(err)) {
                return;
            }
            let msg;

            if (err?.response?.data?.msg) {
                msg = err.response.data.msg;
            } else {
                msg = additionalMsg;
                console.warn('On "$errorMessage()". Cannot get error response msg');
            }
            if (err) {
                console.error(additionalMsg, err);
            }
            // FIX ANY
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this as any).$message({
                type: 'error',
                message: msg,
                showClose: true,
                duration: 20000
            });
        },

        async $checkFormValid(formEl: FormInstance | undefined): Promise<void> {
            if (!formEl) {
                const errMsg = 'Cannot find form ref';
                throw errMsg;
            }
            await formEl.validate((valid) => {
                if (!valid) {
                    const errMsg = 'invalid';
                    throw errMsg;
                }
            });
        }
    }
};
