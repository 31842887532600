import {
    ALL_SUPPORTED_EXCHANGES,
    Exchange,
    EXCHANGE_SUPPORTED_MARKET_TYPE,
    EXCHANGE_TEXT,
    ExchangeName,
    MARKET_TYPE_TEXT,
    MarketType
} from 'enum';

interface IExchangeListItem {
    value: Exchange,
    text: string,
    isDisabled: boolean
}

class ExchangeService {
    text(exchange: Exchange): string {
        const [first, second]: [ExchangeName, MarketType] = exchange.split('-') as [ExchangeName, MarketType];
        return `${EXCHANGE_TEXT[first]} ${second ? MARKET_TYPE_TEXT[second] : ''}`;
    }

    img(exchange: Exchange): string {
        switch (exchange) {
            case ExchangeName.BINANCE:
                return require('@/assets/img/binance-small.svg');
            case `${ExchangeName.BINANCE}-${MarketType.SPOT}`:
                return require('@/assets/img/binance-small.svg');
            case `${ExchangeName.BINANCE}-${MarketType.FUTURES}`:
                return require('@/assets/img/binance-futures-small.svg');
            default:
                return require('@/assets/img/unknown.svg');
        }
    }

    isFutures(exchange: Exchange): boolean {
        return exchange.split('-')[1] !== MarketType.SPOT;
    }

    list(userExchanges: string[] = []): IExchangeListItem[] {
        const result: IExchangeListItem[] = [];
        for (const exchange of ALL_SUPPORTED_EXCHANGES) {
            for (const marketType of EXCHANGE_SUPPORTED_MARKET_TYPE[exchange]) {
                result.push({
                    value: `${exchange}-${marketType}`,
                    text: `${EXCHANGE_TEXT[exchange]} ${MARKET_TYPE_TEXT[marketType]}`,
                    isDisabled: !userExchanges.includes(`${exchange}-${marketType}`)
                });
            }
        }
        return result;
    }
}

export default ExchangeService;
