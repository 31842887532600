import { createStore } from 'vuex';
import {
    UPDATE_ADMIN_CONSOLE_USER_DATA,
    UPDATE_USER_DATA,
    UPDATE_STRATEGY_DATA,
    UPDATE_USER_SEARCH
} from '@/store/mutation-types';
import persistedState from 'vuex-persistedstate';

export default createStore({
    state: {
        adminConsoleUser: {},
        user: {},
        strategy: {},
        userSearch: {
            type: '',
            value: ''
        }
    },
    mutations: {
        [UPDATE_ADMIN_CONSOLE_USER_DATA](state, data) {
            state.adminConsoleUser = data;
        },
        [UPDATE_USER_DATA](state, data) {
            state.user = data;
        },
        [UPDATE_STRATEGY_DATA](state, strategy) {
            state.strategy = strategy;
        },
        [UPDATE_USER_SEARCH](state, value) {
            state.userSearch = value;
        }
    },
    getters: {
        getAdminConsoleUserData(state) {
            return state.adminConsoleUser;
        },
        getUserData(state) {
            return state.user;
        },
        getStrategyData(state) {
            return state.strategy;
        },
        getUserSearch(state) {
            return state.userSearch;
        }
    },
    plugins: [persistedState({ paths: ['user', 'referralId', 'strategy', 'userSearch'] })]
});
