export default {
    // common
    yes: 'Yes',
    no: 'No',
    name: 'Name',
    email: 'E-mail',
    status: 'Status',
    expiration: 'Expiration',
    balance: 'Balance',
    registrationDate: 'Registration date',
    registrationDateShort: 'Reg. date',
    activated: 'Activated',
    activatedShort: 'Act',
    confirmed: 'Confirmed',
    confirmedShort: 'Conf',
    search: 'Search',
    send: 'Send',
    create: 'Create',
    load: 'Load',
    created: 'Created',
    referralWord: 'Referral code',
    additionalOptions: 'Additional options',
    userChannelName: 'Channel name',
    id: 'ID',
    compainCode: 'Compain code',
    twoFactorAuthentification: 'Two-factor authentification',
    twoFactorAuthentificationShort: '2FA',
    cancel: 'Cancel',
    date: 'Date',
    network: 'Network',
    add: 'Add',
    optional: 'Optional',
    change: 'Change',
    value: 'Value',
    link: 'Link',
    warning: 'Warning',
    backToPage: 'Back to page',
    loginWord: 'Sign in',
    strategyWord: 'Strategy',
    detail: 'Detail',
    all: 'All',
    or: 'or',
    role: 'Role',
    apply: 'Apply',
    more: 'More',
    country: 'Country',
    lastSeenDay: 'Last active',
    exchanges: 'Exchanges',
    copy: 'Copy',
    copyed: 'Copyed',
    remove: 'Remove',
    save: 'Save',
    tag: 'Tag',
    from: 'From',
    to: 'To',
    userId: 'User ID',

    // validation
    enterText: 'Enter text',
    enterValue: 'Enter value',
    enterId: 'Enter ID',
    enterName: 'Enter name',
    enterLink: 'Enter link',
    enterEmail: 'Enter E-mail',
    enterCorrectEmail: 'Enter correct E-mail',
    enterPassword: 'Enter password',
    enterCode: 'Enter 2FA code',
    enterRecaptcha: 'Pass recaptcha',
    enterCountry: 'Enter country',
    enterCorrectCountry: 'Pick country from the list',
    textLength: 'Length sould be {from} to {to}',
    tagValidationErrorText: 'Only eng letters, digits & "_" "," symbols',

    // pluralization

    second: '0s | {n}s | {n}s',
    minute: '0m | {n}m | {n}m',
    hour: '0h | {n}h | {n}h',
    day: '0d | {n}d | {n}d',
    week: '0w | {n}w | {n}w',
    month: '0 mth | {n} mth | {n} mth',
    secondFullText: '0 seconds | {n} second | {n} seconds',
    minuteFullText: '0 minutes | {n} minute | {n} minutes',
    hourFullText: '0 hours | {n} hour | {n} hours',
    dayFullText: '0 days | {n} day | {n} days',
    weekFullText: '0 weeks | {n} week | {n} weeks',
    monthFullText: '0 months | {n} month | {n} months',

    elementDatePicker: {
        startDate: 'Start date',
        separator: 'to',
        endDate: 'End date'
    },

    // pages/components
    login: {
        password: 'Password',
        code: '2FA code'
    },
    users: {
        pageTitle: 'Search users or strategies',
        openUserPageBtnTitle: 'Open user page',
        editUserPageBtnTitle: 'Edit user page',
        searchByEmailOrId: 'Search user by E-mail or ID',
        searchByRole: 'Search user by role',
        searchByDate: 'Search user by creation date',
        lastWeek: 'Week',
        lastMounth: 'Mounth',
        lastTreeMounth: '3 mounth',
        userId: 'User ID',
        searchByStrategyId: 'Search strategy by ID',
        strategyId: 'Strategy ID',
        error: {
            indicateStatusOrCreationDate: 'Indicate status or creation date'
        }
    },
    user: {
        pageTitle: 'User',
        mainData: 'Main data',
        changeMainData: 'Change main data',
        changeLicence: 'Change licence',
        messageSent: 'Message sent',
        portfolioCacheCleared: 'Portfolio cache cleared',
        btn: {
            userReferrals: 'User referrals',
            accruals: 'Accruals',
            portfolio: 'Portfolio',
            strategies: 'Strategies',
            sendTelegramMsg: 'Message to Telegram',
            resetCache: 'Reset portfolio cache'
        },
        modal: {
            sendTelegramMsg: {
                title: 'Message to Telegram',
                text: 'Text',
                textPlaceholder: 'Enter your message'
            },
            changeLicence: {
                newLicence: 'New licence'
            },
            changeLicenceLink: {
                title: 'Payment link'
            }
        }
    },
    userSettings: {
        parameters: 'Parameters',
        name: 'Name',
        change: 'Change',
        changeValue: 'Change value',
        changeValueTo: 'Change value to',
        parameterChanged: 'Parameter changed',
        on: 'ON',
        off: 'OFF'
    },
    telegramBots: {
        pageTitle: 'Telegram bots',
        createTelegramBot: 'Create Telegram bot',
        enabled: 'Enabled',
        telegramBotAdded: 'New Telegram bot added',
        enterToken: 'Enter API token',
        modal: {
            title: 'New Telegram bot',
            link: 'Bot link',
            token: 'API token'
        }
    },
    referrals: {
        referrals: 'Referrals',
        userReferrals: 'User referrals',
        code: 'Code',
        addNewReferral: 'Add new referral',
        name: 'Name',
        created: 'Created',
        comment: 'Comment',
        expire: 'Expire',
        credit: 'Credit',
        limit: 'Limit',
        registrations: 'Registrations',
        programReferrals: 'Program referrals',
        programId: 'Program ID',
        copyLink: 'Copy referral link',
        pickDate: 'Pick date',
        enterName: 'Enter name',
        userId: 'User ID',
        rid: 'Rid',
        tag: 'Tag',
        role: 'Role',
        disable: 'Disable',
        disableReferral: 'Disable referral',
        status: 'Status',
        successMsg: {
            referralLinkCopied: 'Referral link copied',
            referralDisabled: 'Referral disabled',
            referralAdded: 'New referral added'
        }
    },
    referralProgram: {
        addNewReferralProgram: 'Add new program',
        added: 'Program created',
        campaign: 'Campaign',
        claim: 'Claim',
        comment: 'Comment',
        description: 'Description',
        status: 'Status',
        fixed: 'Fixed',
        pageTitle: 'Referral programs',
        percent: 'Percent',
        referral: 'Referral',
        referrer: 'Referrer',
        summary: 'Summary',
        updated: 'Program updated'
    },
    createReferralProgram: {
        addNewClaim: 'Add new claim',
        campaign: 'Campaign',
        claim: 'Claim',
        comment: 'Comment',
        description: 'Description',
        descriptionEN: 'Description (EN)',
        descriptionRU: 'Description (RU)',
        status: 'Status',
        days: 'Days',
        referral: 'Referral',
        referrer: 'Referrer',
        summary: 'Summary',
        summaryEN: 'Summary (EN)',
        summaryRU: 'Summary (RU)',
        title: 'Add new referral program',
        type: 'Type',
        updateTitle: 'Update referral program',
        value: 'Value'
    },
    portfolio: {
        pageTitle: 'Portfolio of the user',
        hideLow: 'Hide low value assets',
        asset: 'Asset',
        total: 'Total balance',
        free: 'Free balance',
        reserve: 'Reserved (exchange/strategies)',
        value: 'Value',
        headerTotal: 'Total',
        logs: {
            title: 'Load portfolio logs',
            dayAgo: 'Last day',
            threeDaysAgo: 'Last 3 days',
            fiveDaysAgo: 'Last 5 days'
        }
    },
    accruals: {
        pageTitle: 'Referral accruals',
        points: 'Points',
        reason: 'Description',
        comment: 'Comment',
        change: 'Change',
        successMsg: {
            onAdd: 'Successful change'
        }
    },
    changeUserDataModal: {
        title: 'Change data',
        comment: 'Comment',
        saveChanges: 'Save changes',
        deactivate: 'Deactivate',
        text1: 'Are you sure you want to',
        text2: 'deactivate user?',
        text3: 'Deactivation will be available in',
        sec: 'sec.',
        userDataChanged: 'User data changed'
    },
    header: {
        menu: 'Menu',
        confirmText: 'Leave the admin panel and log out?',
        page: {
            users: 'Users',
            referrals: 'Referrals',
            telegramBots: 'Telegram bots',
            billing: 'Billing',
            referralPrograms: 'Referral Programs',
            receipts: 'Receipts'
        },
        logout: 'Exit',
        hello: 'Hello,'
    },
    strategies: {
        pageTitle: 'Strategies of the user',
        userId: 'User ID',
        table: {
            type: 'Type',
            marketAndPair: 'Market/pair',
            reserve: 'Reserve',
            strategyId: 'Strategy ID',
            runId: 'Run ID'
        },
        openStrategyPage: 'Open strategy page',
        strategyOrders: 'Strategy orders'
    },
    strategy: {
        backToStrategiesList: 'Back to strategies list',
        backToUserPage: 'To user page',
        active: 'Active',
        inactive: 'Inactive',
        pending: 'Pending',
        stopStrategy: 'Stop strategy',
        deleteStrategy: 'Delete strategy',
        config: 'Config',
        info: 'Info',
        data: 'Data',
        logs: {
            title: 'Load strategy logs',
            dayAgo: 'Last day',
            threeDaysAgo: 'Last 3 days',
            fiveDaysAgo: 'Last 5 days'
        },
        tableData: {
            type: 'Type',
            name: 'Name',
            exchange: 'Exchange',
            symbol: 'Symbol',
            status: 'Status',
            started: 'Started',
            direction: 'Direction (long/short)',
            timeframe: 'Timeframe',
            balanceTotal: 'Balance total',
            balanceAllocated: 'Balance allocated',
            profit: 'Profit',
            userId: 'User ID',
            strategyId: 'Strategy ID',
            strategyRunId: 'Strategy run ID',
            notice: 'Notice',
            orders: 'Orders',
            stoploss: 'Stoploss',
            state: 'State',
            allocation: 'Allocation'
        }
    },
    groupTelegramMessage: {
        btn: 'Group Telegram message',
        modal: {
            title: 'Group Telegram message',
            text: 'Text',
            textPlaceholder: 'Enter your message',
            role: 'Status',
            activeStatus: 'Active status',
            active: 'Active',
            inactive: 'Inactive',
            messageSent: 'Group Telegram message sent'
        }
    },
    groupEmailMessage: {
        btn: 'Group E-mail message',
        modal: {
            title: 'Group E-mail message',
            subject: 'Subject',
            text: 'Text',
            textPlaceholder: 'Enter your message',
            role: 'Role',
            activeStatus: 'Active status',
            active: 'Active',
            inactive: 'Inactive',
            messageSent: 'Group E-mail message sent',
            sendingParameters: 'Sending parameters',
            options: 'Options',
            list: 'IDs list',
            listPlaceholder: 'Enter users IDs separated by commas'
        }
    },
    billing: {
        pageTitle: 'Billing',
        promoCode: {
            createPromoCode: 'Create licence promo code',
            readPromoCode: 'Get promo code info',
            deletePromoCode: 'Delete promo code',
            code: 'Promo code',
            expires: 'Expires',
            pickDate: 'Pick date',
            codeType: 'Promo code type',
            singleUse: 'One-time',
            startDate: 'Start from',
            codeTypeLicense: 'Promo license',
            codeTypePurchase: 'Discount',
            licence: 'Licence',
            percent: 'Discount percent',
            userId: 'User ID',
            allData: 'All data',
            validation: {
                enterCode: 'Enter code',
                enterDate: 'Enter date',
                pickType: 'Pick promo code type',
                pickLicence: 'Pick licence',
                enterPercent: 'Enter discount percent',
                percentRule: 'Percent must be more tan 0 and less than 100'
            }
        },
        error: {
            getLicencesList: 'Error on get licences list',
            createPromoCode: 'Error on create promo code',
            readPromoCode: 'Error on read promo code',
            deletePromoCode: 'Error on delete promo code'
        },
        success: {
            createPromoCode: 'New promo code created',
            deletePromoCode: 'Promo code removed'
        }
    },
    receipts: {
        pageTitle: 'Receipts',
        dateRange: 'Date range (UTC+0)',
        dateAndTime: 'Date time (UTC+0)',
        dateAndTimeLocal: 'Date time (local)',
        defaultRangeText: 'By default - current day (from 00:00 UTC)',
        licence: 'Licence',
        price: 'Price',
        viewUserData: 'View user data',
        loadUserData: 'Load user data ("User ID" button)',
        goToUserPage: 'Go to user page',
        downloadReceipt: 'Download receipt',
        merchantTradeId: 'Merchant Trade ID',
        duration: 'Duration',
        licenseId: 'License ID',
        invoiceId: 'Invoice ID'
    },
    countryCodes: {
        AF: 'Afghanistan',
        US: 'United States of America',
        AL: 'Albania',
        DZ: 'Algeria',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua and Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia, Plurinational State of',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        CV: 'Cape Verde',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Congo, The Democratic Republic of the Congo',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'Cote d\'Ivoire',
        HR: 'Croatia',
        CU: 'Cuba',
        CY: 'Cyprus',
        CZ: 'Czech Republic',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        VA: 'Holy See (Vatican City State)',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran, Islamic Republic of Persian Gulf',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: 'Korea, Democratic People\'s Republic of Korea',
        KR: 'Korea, Republic of South Korea',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Laos',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libyan Arab Jamahiriya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MK: 'Macedonia',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia, Federated States of Micronesia',
        MD: 'Moldova',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        AN: 'Netherlands Antilles',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestinian Territory, Occupied',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RO: 'Romania',
        RU: 'Russian Federation',
        RW: 'Rwanda',
        RE: 'Reunion',
        BL: 'Saint Barthelemy',
        SH: 'Saint Helena, Ascension and Tristan Da Cunha',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        MF: 'Saint Martin',
        PM: 'Saint Pierre and Miquelon',
        VC: 'Saint Vincent and the Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SG: 'Singapore',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        SS: 'South Sudan',
        GS: 'South Georgia and the South Sandwich Islands',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard and Jan Mayen',
        SZ: 'Swaziland',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan',
        TJ: 'Tajikistan',
        TZ: 'Tanzania, United Republic of Tanzania',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks and Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela, Bolivarian Republic of Venezuela',
        VN: 'Vietnam',
        VG: 'Virgin Islands, British',
        WF: 'Wallis and Futuna',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
        SC: 'Seychelles',
        SL: 'Sierra Leone'
    }
};
