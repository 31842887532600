export const TIME_CONSTANT = {
    SECOND_MILLISECONDS: 1000,
    MINUTE_MILLISECONDS: 60 * 1000,
    HOUR_MILLISECONDS: 60 * 60 * 1000,
    DAY_MILLISECONDS: 24 * 60 * 60 * 1000,
    WEEK_MILLISECONDS: 7 * 24 * 60 * 60 * 1000,
    MONTH_MILLISECONDS: 30 * 24 * 60 * 60 * 1000,
    YEAR_MILLISECONDS: 365 * 24 * 60 * 60 * 1000
};

export const USER_ROLE = {
    STANDARD: 'standard',
    PRO: 'pro',
    FREE: 'free',
    TRIAL: 'trial',
    ADMIN: 'admin',
    SUPPORT: 'support'
};

export const USER_ROLE_TEXT = {
    [USER_ROLE.STANDARD]: 'Standard',
    [USER_ROLE.PRO]: 'Pro',
    [USER_ROLE.FREE]: 'Free',
    [USER_ROLE.TRIAL]: 'Trial',
    [USER_ROLE.ADMIN]: 'Admin',
    [USER_ROLE.SUPPORT]: 'Support'
};

export const STRATEGY_TYPE = {
    FIBO: 'FIBO',
    SQUEEZE: 'SQUEEZE',
    CHANNEL: 'CHANNEL',
    ALGO: 'ALGO',
    INTRADAY: 'INTRADAY',
    NOTIFY: 'NOTIFY',
    HOLDER: 'HOLDER'
};

export const NOTIFY_STRATEGY = {
    WATCHER: 'watcher',
    ALARM: 'alarm'
};

export enum MarketType {
    SPOT = 'spot',
    MARGIN = 'margin',
    SWAP = 'swap',
    FUTURES = 'futures',
    OPTION = 'option'
}

export enum ExchangeName {
    BINANCE = 'binance',
    BITMEX = 'bitmex',
    BITSTAMP = 'bitstamp',
    HUOBI = 'huobi',
    OKX = 'okx'
}

export type Exchange = `${ExchangeName}-${MarketType}` | ExchangeName.BINANCE;

export const EXCHANGE_TEXT = {
    [ExchangeName.BINANCE]: 'Binance',
    [ExchangeName.BITMEX]: 'Bitmex',
    [ExchangeName.BITSTAMP]: 'Bitstamp',
    [ExchangeName.HUOBI]: 'Huobi',
    [ExchangeName.OKX]: 'OKX'
};

export const LETTER_EXCHANGE_TEXT = {
    [ExchangeName.BINANCE]: 'B.',
    [ExchangeName.BITMEX]: 'Btx.',
    [ExchangeName.BITSTAMP]: 'Bts.',
    [ExchangeName.HUOBI]: 'H.',
    [ExchangeName.OKX]: 'O.'
};

export const ALL_SUPPORTED_EXCHANGES = [
    ExchangeName.BINANCE,
    ExchangeName.OKX
];

export const MARKET_TYPE_TEXT = {
    [MarketType.SPOT]: 'Spot',
    [MarketType.MARGIN]: 'Margin',
    [MarketType.SWAP]: 'Futures',
    [MarketType.FUTURES]: 'Futures',
    [MarketType.OPTION]: 'Option'
};

export const LETTER_MARKET_TYPE_TEXT = {
    [MarketType.SPOT]: 'S',
    [MarketType.MARGIN]: 'M',
    [MarketType.SWAP]: 'F',
    [MarketType.FUTURES]: 'F',
    [MarketType.OPTION]: 'O'
};

export const EXCHANGE_SUPPORTED_MARKET_TYPE: Record<ExchangeName, MarketType[]> = {
    [ExchangeName.BINANCE]: [MarketType.SPOT, MarketType.FUTURES],
    [ExchangeName.BITMEX]: [],
    [ExchangeName.BITSTAMP]: [],
    [ExchangeName.HUOBI]: [],
    [ExchangeName.OKX]: [MarketType.SPOT, MarketType.FUTURES]
};

export const BE_EXCHANGE_TO_UI_EXCHANGE: Record<string, Exchange> = {
    [`${ExchangeName.BINANCE}-${MarketType.SPOT}`]: `${ExchangeName.BINANCE}-${MarketType.SPOT}`,
    [`${ExchangeName.BINANCE}-${MarketType.FUTURES}`]: `${ExchangeName.BINANCE}-${MarketType.FUTURES}`,
    [`${ExchangeName.OKX}-${MarketType.SPOT}`]: `${ExchangeName.OKX}-${MarketType.SPOT}`,
    [`${ExchangeName.OKX}-${MarketType.SWAP}`]: `${ExchangeName.OKX}-${MarketType.FUTURES}`,
    [`${ExchangeName.OKX}-${MarketType.FUTURES}`]: `${ExchangeName.OKX}-${MarketType.FUTURES}`
};

export const BE_MT_TO_UI_MT: Record<string, MarketType> = {
    [MarketType.SPOT]: MarketType.SPOT,
    [MarketType.SWAP]: MarketType.FUTURES,
    [MarketType.FUTURES]: MarketType.FUTURES
};

export const STRATEGY_STATUS = {
    NEW: 'NEW',
    STARTED: 'STARTED',
    STOPPED: 'STOPPED',
    ERROR: 'ERROR'
};

export const STRATEGY_STATE = {
    PENDING: 'Pending',
    TRADING_ERROR: 'TradingError',
    SELL_ONLY: 'Sell only',
    STOPPING: 'Stopping'
};

export const PURCHASE_OPERATION_TYPE = {
    PURCHASE: 'purchase',
    UPGRADE: 'upgrade',
    UPDATE: 'update'
};

export const PROMO_CODE_TYPE = {
    PRICE_CUT: 'price-cut', // default
    LICENSE: 'license'
};

export const REGISTRATION_COUNTRY_CODES = ['AF', 'AL', 'DZ', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BA', 'BW', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CK', 'CR', 'HR', 'CU', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'EE', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GT', 'GG', 'GN', 'GY', 'HT', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KR', 'KW', 'KG', 'LA', 'LV', 'LS', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'AN', 'NC', 'NZ', 'NI', 'NG', 'NU', 'NF', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'QA', 'RO', 'RU', 'RW', 'RE', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SK', 'SI', 'SB', 'ZA', 'GS', 'ES', 'LK', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'WF', 'ZM', 'ZW'];
