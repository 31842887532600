import { App as Application } from 'vue';
import { createAuth } from '@websanova/vue-auth';
import bearer from '@/core/auth/bearer';
// @ts-ignore
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm';
// @ts-ignore
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm';
import router from '@/router/index';

export default (app: Application): void => {
    app.use(createAuth({
        plugins: {
            // @ts-ignore
            http: app.axios,
            router: router
        },
        drivers: {
            http: driverHttpAxios,
            auth: bearer,
            router: driverRouterVueRouter
        },
        options: {
            forbiddenRedirect: { path: '/403' },
            authRedirect: { path: '/login' },
            notFoundRedirect: { path: '/users' },
            loginData: {
                url: 'auth/login',
                method: 'POST',
                redirect: '/users',
                fetchUser: false
            },
            logoutData: {
                url: 'auth/logout',
                method: 'POST',
                redirect: '/',
                makeRequest: true
            },
            fetchData: { enabled: false }
        }
    }));
};
