/* eslint-disable import/no-extraneous-dependencies */
import { createApp } from 'vue';
import http from '@/core/http';
import store from '@/store';
import router from '@/router';
import auth from '@/core/auth/auth';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './assets/style/element/darkThemeColors.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import App from '@/core/App.vue';
import componentMixins from '@/helpers/componentMixins';
import i18n from './i18n';
import service from './core/service';

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

app.mixin(componentMixins);

app
.use(http)
.use(store)
.use(router)
.use(auth)
.use(ElementPlus)
.use(i18n)
.use(service)
.mount('#app');
